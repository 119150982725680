<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <div class="">
        <v-main class="grey">
          <v-app-bar id="menu-bar" class="white" v-show="show_sidebar" flat fixed>            
            <v-toolbar-title>
              <div class="my-1 text-left">
                <img
                  src="@/assets/images/brand.png"
                  alt="Avatar"
                  class="img-yec-test"
                  @click="beranda()"
                  style="cursor: pointer;"
                />
              </div>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn @click="beranda()" class="me-2 transparent primary--text d-none d-md-block" elevation="0" >
              Beranda
            </v-btn>
            <v-btn @click="prakerja()" class="me-2 transparent primary--text d-none d-md-block" elevation="0" >
              Kelas Prakerja
            </v-btn>
            <v-btn @click="signIn()" class="me-2 transparent primary--text d-none d-md-block" elevation="0" >
              Masuk
            </v-btn>

            <v-btn @click="signUp()" color="primary" class="rounded-xl d-none d-md-block" elevation="0">
              Daftar
            </v-btn>
            <v-app-bar-nav-icon
              v-if="!$vuetify.breakpoint.mdAndUp"
              @click="dialog.menu = true"
            >
            </v-app-bar-nav-icon>
            <v-dialog
              v-model="dialog.menu"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar
                >
                  <v-toolbar-title>
                    <div class="my-1 text-left">
                      <img
                        src="@/assets/images/brand.png"
                        alt="Avatar"
                        class="img-yec-test"
                      />
                    </div>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn
                      icon
                      dark
                      @click="dialog.menu = false"
                      color="primary"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer></v-spacer>
                <v-card-text class="pa-5">
                  <v-btn @click="signUp();dialog.menu = false" color="primary" class="rounded-xl mb-4" block elevation="0">
                    Daftar
                  </v-btn>
                  <v-btn @click="signIn();dialog.menu = false" class=" transparent primary--text" block elevation="0" >
                    Masuk
                  </v-btn>
                  <v-btn @click="prakerja();dialog.menu = false" class=" transparent primary--text" block elevation="0" >
                    Kelas Prakerja
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-app-bar>

          <v-container fluid style="margin-top: 64px;padding: 0px;">
            <transition name="fade">
              <router-view v-if="loaded"></router-view>
            </transition>
          </v-container>

          <div id="footer" class="py-3 white" v-show="show_sidebar">
            <div class="container">
              <div class="row">
                <div class="col-md-5">
                  <img
                    src="@/assets/images/brand.png"
                    alt="Avatar"
                    class="mb-2 img-yec-test"
                  />
                  <div class="pe-5">
                    <p class="fs-16 fs-xs-14">
                      Sertifikasi dan Kelas Bahasa Inggris Online. Mengembangkan kelas bahasa Inggris inklusif untuk semua orang, serta kelas pengembangan diri lainnya.
                    </p>
                    <p class="fs-16 fs-xs-14 mb-0">
                      Lembaga Pelatihan YEC CO ID juga tergabung dalam program Kartu Prakerja dan berafiliasi dengan Digital Platform seperti Karier.mu, Tokopedia, Pintar, Pijar Mahir, dan Bukalapak.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <p class="fs-18 fs-xs-16 font-weight-bold mb-2">Alamat</p>
                  <div class="pe-5">
                    <p class="fs-16 fs-xs-14 mb-0">
                      Jl. Nogotirto no.14 Modinan Banyuraden Gamping Sleman Yogyakarta 55293
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <p class="fs-18 fs-xs-16 font-weight-bold mb-2">Customer Service</p>
                  <span class="mb-2">
                    <a href="http://facebook.com/groups/prakerja.yec" 
                      target="_blank">
                      <img
                        src="@/assets/images/FB.png"
                        alt="Avatar"
                        class="mr-2"
                        style="width: 20px"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/kelasprakerja.yec/"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/instagram 1.png"
                        alt="Avatar"
                        class="mr-2"
                        style="width: 20px"
                      />
                    </a>
                    <a
                      href="https://t.me/joinchat/A9ea-qrWsoozZDE1"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/telegram 1.png"
                        alt="Avatar"
                        class="mr-2"
                        style="width: 20px"
                      />
                    </a>
                    <a
                      href="https://wa.me/message/RU2RNW7CT4YRL1"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/whatsapp 1.png"
                        alt="Avatar"
                        class=""
                        style="width: 20px"
                      />
                    </a>
                  </span>
                  <p class="fs-16 fs-xs-14 mb-0">Email : prakerja@yec.co.id</p>
                  <p class="fs-16 fs-xs-14">CS: +62 853-3584-9962 (WhatsApp Chat)</p>
                </div>
              </div>
            </div>
          </div>
        </v-main>
      </div>
    </v-app>
  </transition>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      breadcrumbs: [],
      loaded: true,
      drawer: false,
      group:null,
      notifications: [1],
      pageTitle: "",
      // title:false,
      pathnya: "",
      dialog:{
        menu:false,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),

    show_sidebar() {
      return !/\/auth\/.\.*/.test(this.$route.path);
    },
  },
  created() {
    // this.disableRight();
    this.$root.$on("setPageTitle", (pageTitle) => {
      this.pageTitle = pageTitle;
      // console.log(pageTitle);
    });

    // let path = "/" + this.$route.path.split("/")[1];
    this.pathnya = this.$route.path;
    // console.log("pathnya", this.pathnya);
    // let index = this._.findIndex(this.items, { link: path });
    // if (index != -1) {
    //   this.pageTitle = this.items[index].title;
    //   this.breadcrumbs.push({
    //     text: this.items[index].title,
    //     disabled: true,
    //     href: path,
    //   });
    // }
  },
  methods: {
    menuItems() {
      return this.menu;
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
        window.location.reload(true);
      });
    },
    beranda(){
      this.$router.push("/");
    },
    signUp(){
      this.$router.push("/auth/registration");
    },
    signIn(){
      this.$router.push("/auth/login");
    },
    prakerja(){
      this.$router.push("/kelas-prakerja");
    },
    // menu() {
    //   this.$router.push("/");
    // },
    updateLocation(item) {
      // this.pageChanged(0, item);
      this.pageTitle = item.title;
    },
    pageChanged(segment, location = null) {
      // console.log(segment);
      // console.log(location);
      this.pathnya = this.$route.path;
      // console.log("pathpage", this.pathnya);
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }
      // window.location.reload(true);
      document.title =
        this.breadcrumbs.map((val) => val.text).join(" -> ") +
        " - " +
        process.env.VUE_APP_TITLE;
    },
  },
};
</script>

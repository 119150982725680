<template>
  <div class="">
    <hr style="border-color: transparent;">
    <section class="mt-5 mb-5 white">
      <div class="col-md-10 mx-auto">
          <img src="@/assets/images/Poster Siap Kerja dengan Kartu Prakerja.png" class="img-kategori"/>
          <p class="fs-24 fs-xs-20 font-weight-bold my-2">Siap Kerja Dengan Kartu Prakerja</p>
          <p>
            PT Yureka Edukasi Cipta yang memiliki nama brand YEC CO ID berdiri pada tahun 2010 dengan nama awal Eureka Tour, sebuah layanan jasa tour spesialis Kampung Inggris. Saat ini YEC fokus membantu setiap peserta menjadi individu lebih baik. Tidak hanya sebatas menjadi jasa tour, namun juga menjadi konsultan pendidikan dan media yang membantu menjadikan diri lebih baik dengan kemudahan akses informasi dan program pelatihan terkait tes TOEFL, IELTS, Persiapan Kerja dan Pembelajaran Bahasa Inggris Sehari-hari.
          </p>
          <p>
            Serta pada tahun 2020, YEC CO ID bergabung bersama program Kartu Prakerja, memberikan pelatihan kepada peserta kartu prakerja yang memiliki keinginan untuk mengembangkan keahlian agar lebih percaya diri terjun ke dunia kerja, maupun membuat usaha.
          </p>
          <div class="pt-3 tab-prakerja">
            <div class="row">
              <div class="col-md-4">
                <v-card
                  class="mx-auto sticky-sidebar"
                >
                  <v-list id="tab-ver">
                    <v-list-item-group>
                      <v-list-item href="#program">
                        <v-list-item-content>
                          <v-list-item-title>A. Program Kartu Prakerja</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item href="#syarat">
                        <v-list-item-content>
                          <v-list-item-title>B. Persyaratan Pendaftaran Kartu Prakerja</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item href="#langkah">
                        <v-list-item-content>
                          <v-list-item-title>C. Langkah Pendaftaran Kartu Prakerja</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item href="#mengapa">
                        <v-list-item-content>
                          <v-list-item-title>Mengapa Harus Memilih Kelas Pelatihan di YEC.CO.ID?</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item href="#kelas">
                        <v-list-item-content>
                          <v-list-item-title>Kelas-kelas Yang Dirancang oleh YEC.CO.ID</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item href="#faq">
                        <v-list-item-content>
                          <v-list-item-title>FAQ</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </div>
              <div class="col-md-8">
                <div id="program" class="mb-4">
                  <h3 class="font-weight-bold pb-2">A. Program Kartu Prakerja</h3>
                  <p>Program Kartu Prakerja adalah program pengembangan kompetensi kerja dan kewirausahaan yang ditujukan untuk pencari kerja, pekerja/buruh yang terkena pemutusan hubungan kerja, dan/atau pekerja/buruh yang membutuhkan peningkatan kompetensi, termasuk pelaku usaha mikro dan kecil. Kami percaya bahwa masyarakat Indonesia sesungguhnya ingin selalu meningkatkan kemampuannya. Program ini didesain sebagai sebuah produk dan dikemas sedemikian rupa agar memberikan nilai bagi pengguna sekaligus memberikan nilai bagi sektor swasta.</p>
                  <p>Jalan digital melalui marketplace dipilih untuk memudahkan pengguna mencari, membandingkan, memilih dan memberi evaluasi. Hanya dengan cara ini, produk bisa terus diperbaiki, tumbuh dan relevan. Menggandeng pelaku usaha swasta, program ini adalah wujud kerjasama pemerintah dan swasta dalam melayani masyarakat dengan semangat gotong royong demi SDM unggul, Indonesia maju.</p>
                </div>
                <div id="langkah" class="mb-4">
                  <h3 class="font-weight-bold mb-2">B. Persyaratan Pendaftaran Kartu Prakerja</h3>
                  <ul class="mb-3">
                    <li>WNI berusia paling rendah 18 tahun dan paling tinggi 64 tahun</li>
                    <li>Tidak sedang menempuh pendidikan formal.</li>
                    <li>Sedang mencari kerja, pekerja/buruh yang terkena PHK, atau pekerja/buruh yang membutuhkan peningkatan kompetensi kerja, seperti pekerja/buruh yang dirumahkan dan pekerja bukan penerima upah, termasuk pelaku usaha mikro & kecil.</li>
                    <li>Bukan Pejabat Negara, Pimpinan dan Anggota DPRD, Aparatur Sipil Negara, Prajurit TNI, Anggota Polri, Kepala Desa dan perangkat desa dan Direksi/Komisaris/Dewan Pengawas pada BUMN atau BUMD.</li>
                    <li>Maksimal 2 NIK dalam 1 KK yang menjadi Penerima Kartu Prakerja.</li>
                  </ul>
                </div>
                <div id="langkah" class="mb-4">
                  <h3 class="font-weight-bold mb-2">C. Langkah Pendaftaran Kartu Prakerja</h3>
                  <ol class="mb-3">
                    <li>
                      <b>Daftar</b>
                      <p>
                        Buat akun Prakerja dengan data diri kamu. Siapkan e-mail, NIK, nomor KK, nomor HP yang masih aktif dan selesaikan tes kemampuan dasar untuk menyelesaikan proses pendaftaran.
                      </p>
                    </li>
                    <li>
                      <b>Gabung Gelombang</b>
                      <p>
                        Ikut Seleksi dengan gabung gelombang. Tunggu pengumuman hasil seleksinya.
                      </p>
                    </li>
                    <li>
                      <b>Pilih Pelatihan</b>
                      <p>
                        Gunakan saldo pelatihan sebesar Rp 3,5 juta, pilih yang kamu butuhkan di Mitra Platform Digital dan bayar dengan nomor Kartu Prakerjamu. Pastikan rekening bank/e-wallet kamu sudah tersambung sebelum membeli pelatihan.
                      </p>
                    </li>
                    <li>
                      <b>Ikuti Pelatihan</b>
                      <p>
                        Kerjakan pre-test, post-test dan unjuk keterampilan. Selesaikan pelatihan dan dapatkan sertifikat.
                      </p>
                    </li>
                    <li>
                      <b>Beri Rating dan Ulasan</b>
                      <p>
                        Berikan rating dan ulasan terhadap pelatihan yang telah kamu selesaikan di dashboard Prakerjamu.
                      </p>
                    </li>
                    <li>
                      <b>Dapatkan Insentif</b>
                      <p>
                        Tunggu beberapa hari, kamu akan menerima insentif Rp.600.000 di rekening bank/e-walletmu.
                      </p>
                    </li>
                    <li>
                      <b>Isi Survey Evaluasi</b>
                      <p>
                        Jawab 2 survei evaluasi di dashboard Prakerjamu dan dapatkan insentif Rp.50.000 untuk setiap survei
                      </p>
                    </li>
                  </ol>
                </div>
                <div id="pembelian" class="mb-4">
                  <h3 class="font-weight-bold mb-2">D. Cara Pembelian Pelatihan</h3>
                  <p class="mb-2">Kamu bisa pilih berbagai pelatihan dari YEC.CO.ID melalui digital platform berikut:</p>
                  <ol class="mb-3">
                    <li>
                      <b>Karier.mu</b>
                      <p class="mt-2">
                        <a href="https://prakerja.karier.mu/mitra/yec-co-id" target="_blank">
                          <img src="@/assets/images/icon/image-5.png">
                        </a>
                      </p>
                    </li>
                    <li>
                      <b>Tokopedia</b>
                      <p class="mt-2">
                        <a href="https://www.tokopedia.com/kartu-prakerja/partner/yec-co-id" target="_blank">
                          <img src="@/assets/images/icon/image-6.png">
                        </a>
                      </p>
                    </li>
                    <li>
                      <b>Pijar Mahir</b>
                      <p class="mt-2">
                        <a href="https://pijarmahir.id/institution-partner/yec" target="_blank">
                          <img src="@/assets/images/icon/image-8.png">
                        </a>
                      </p>
                    </li>
                    <li>
                      <b>Pintar</b>
                      <p class="mt-2">
                        <a href="https://pintar.co/kartuprakerja#/" target="_blank">
                          <img src="@/assets/images/pintar.png" style="height: 60px; width: auto">
                        </a>
                      </p>
                    </li>
                    <li>
                      <b>Bukalapak</b>
                      <p class="mt-2">
                        <a href="https://www.bukalapak.com/kartu-prakerja/lembaga/pt-yureka-edukasi-cipta-yeccoid-3804?source=tab-merchant" target="_blank">
                          <img src="@/assets/images/bukalapak.png" style="height: 100px; width: auto">
                        </a>
                      </p>
                    </li>
                  </ol>
                </div>
                <div id="mengapa" class="mb-4">
                  <h3 class="font-weight-bold pb-2">E. Mengapa Harus Memilih Kelas Pelatihan di YEC.CO.ID ?</h3>
                  <v-list three-line>
                    <template>
                      <v-list-item>
                        <v-list-item-avatar width="64" height="64">
                          <img src="@/assets/images/icon/Group-1.png">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="mb-1 font-weight-bold">Instruktur Berkompeten</v-list-item-title>
                           <v-list-item-subtitle>Instruktur kelas berupa putra-putri daerah yang berkompeten di bidangnya.</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-avatar width="64" height="64">
                          <img src="@/assets/images/icon/Group-2.png">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="mb-1 font-weight-bold">Grup Eksklusif</v-list-item-title>
                           <v-list-item-subtitle >Grup Pengembangan Karir GRATIS dengan webinar eksklusif secara berkala.</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-avatar width="64" height="64">
                          <img src="@/assets/images/icon/Group-3.png">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="mb-1 font-weight-bold">Admin</v-list-item-title>
                           <v-list-item-subtitle class="mb-2">Admin cepat tanggap di semua media sosial.</v-list-item-subtitle>
                           <span class="mb-2">
                            <a
                              href="https://www.instagram.com/kelasprakerja.yec/"
                              target="_blank"
                            >
                              <img
                                src="@/assets/images/instagram 1.png"
                                alt="Avatar"
                                class="mr-2"
                                style="width: 25px"
                              />
                            </a>
                            <a
                              href="https://t.me/joinchat/HOIYkUuvNkC4pyuN2E-z4w"
                              target="_blank"
                            >
                              <img
                                src="@/assets/images/telegram 1.png"
                                alt="Avatar"
                                class="mr-2"
                                style="width: 25px"
                              />
                            </a>
                            <a href="http://facebook.com/groups/prakerja.yec" 
                              target="_blank">
                              <img
                                src="@/assets/images/FB.png"
                                alt="Avatar"
                                class="mr-2"
                                style="width: 25px"
                              />
                            </a>
                            <a
                              href="https://www.tiktok.com/@panduanprakerja?lang=en"
                              target="_blank"
                            >
                              <img
                                src="@/assets/images/tiktok.png"
                                alt="Avatar"
                                class=""
                                style="width: 25px"
                              />
                            </a>
                          </span>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </div>
                <div id="kelas" class="mb-4">
                <h3 class="font-weight-bold pb-2">F. Kelas-kelas Yang Dirancang oleh YEC.CO.ID</h3>
                <ol class="mb-3">
                    <li>
                      <b>Menggunakan Bahasa Inggris Bagi Calon Sekretaris</b>
                      <v-card class="mx-auto py-2" elevation="0">
                      <img
                        src="@/assets/images/19.png"
                        class="img-kategori"
                      />
                      </v-card>
                      <p>
                        <b>Menggunakan Bahasa Inggris Bagi Calon Sekretaris</b> adalah kelas yang disusun khusus untuk peserta yang ingin belajar Bahasa Inggris khususnya bagi yang berprofesi sebagai Sekretaris. Pelatihan ini menggunakan metode ajar webinar. Kelas ini merujuk pada SKKNI Nomor 27 Tahun 2009.</p>
                        <p>
                        Peserta akan mendapatkan pengetahuan tentang menggunakan Bahasa Inggris bagi seorang calon sekretaris meliputi perkenalan okupasi sekretaris, cakupan tugas, dan pentingnya belajar Bahasa Inggris bagi sekretaris, orang-orang di tempat kerja (people at work), membuat dan menerima panggilan (making and receiving calls), menerima dan memberi pesan (taking and giving messages), menyambut dan menerima tamu (welcoming and receiving visitors), dan membuat surat, memo, dan permohonan (letters, inter-office memos, and requests) mengingat banyaknya perusahaan multinasional yang terdapat di Indonesia serta pentingnya penguasaan Bahasa Inggris sebagai bahasa internasional. 
                       </p>
                    </li>
                    <li>
                      <b>Menggunakan Bahasa Inggris Bagi Calon Tenaga Perkantoran Umum</b>
                      <v-card class="mx-auto py-2" elevation="0">
                      <img
                      src="@/assets/images/1.png"
                        class="img-kategori"
                      />
                      </v-card>
                      <p>
                        Menggunakan Bahasa Inggris Bagi Calon Tenaga Perkantoran Umum adalah kelas yang disusun untuk  peserta yang ingin belajar Bahasa Inggris khususnya bagi yang berprofesi sebagai tenaga perkantoran umum. Pelatihan ini menggunakan metode ajar webinar. Kelas ini merujuk pada SKKNI Nomor 183 Tahun 2016 Administrasi Profesional.</p>
                        <p>
                        Peserta akan mendapatkan pengetahuan tentang menggunakan Bahasa Inggris bagi seorang calon tenaga perkantoran umum meliputi perkenalan okupasi tenaga perkantoran umum, cakupan tugas, dan pentingnya belajar Bahasa Inggris bagi calon tenaga perkantoran umum, bertemu kolega/pelanggan (small talks/basic conversation), membuat-menerima panggilan dan memberikan pesan secara lisan (making-receiving calls and taking a message), meminta dan memberikan saran/usul (asking and giving opinions) meminta dan memberikan klarifikasi (asking for clarification and clarifying one's idea), surat dan pengumuman (letters and announcement) mengingat banyaknya perusahaan multinasional yang terdapat di Indonesia serta pentingnya penguasaan Bahasa Inggris sebagai bahasa internasional.

                       </p>
                    </li>
                    <li>
                      <b>Melakukan Komunikasi Bahasa Inggris Melalui Panggilan Telepon Bagi Calon Petugas Informasi Pusat Kontak</b>
                      <v-card class="mx-auto py-2" elevation="0">
                      <img
                      src="@/assets/images/3.png"
                        class="img-kategori"
                      />
                      </v-card>
                      <p>
                        Melakukan Komunikasi Bahasa Inggris Melalui Panggilan Telepon Bagi Calon Petugas Informasi Pusat Kontak adalah kelas yang disusun khusus untuk peserta yang ingin belajar Bahasa Inggris khususnya bagi yang berprofesi sebagai Petugas Informasi Pusat Kontak. Pelatihan ini menggunakan metode ajar webinar. Kelas ini merujuk pada SKKNI Nomor 27 Tahun 2009 Tentang Bahasa Inggris Tenaga Administrasi Profesional.</p>
                        <p>
                        Peserta akan mendapatkan pengetahuan tentang menggunakan Bahasa Inggris bagi seorang calon  Petugas Informasi Pusat Kontak meliputi perkenalan okupasi  Petugas Informasi Pusat Kontak, cakupan tugas, dan pentingnya belajar Bahasa Inggris bagi Petugas Informasi Pusat Kontak, menggunakan bahasa Inggris untuk melayani pelanggan atau konsumen, menggunakan bahasa Inggris untuk menjawab telepon dalam melayani pelanggan atau konsumen, menginterpretasikan informasi yang diterima dan memiliki kemampuan komunikasi yang efektif, menunjukkan sikap komunikatif dalam berbahasa Inggris, menunjukkan sikap terampil dalam penggunaan peralatan komunikasi mengingat banyaknya perusahaan multinasional yang terdapat di Indonesia serta pentingnya penguasaan Bahasa Inggris sebagai bahasa internasional. 
                       </p>
                    </li>
                    <li>
                      <b>Melakukan Riset Kata Kunci Dan Optimasi SEO Website Untuk Menjadi Manajer Pemasaran</b>
                      <v-card class="mx-auto py-2" elevation="0">
                      <img
                      src="@/assets/images/15.png"
                        class="img-kategori"
                      />
                      </v-card>
                      <p>
                        Melakukan Riset Kata Kunci dan Optimasi SEO Website Untuk Menjadi Manajer Pemasaran adalah kelas yang dikhususkan untuk peserta yang ingin belajar riset kata kunci dan optimasi SEO website. Pelatihan ini menggunakan metode ajar webinar. Kelas ini merujuk pada SKKNI Nomor 389 Tahun 2013 Tentang Penetapan Standar Kompetensi Kerja Nasional Indonesia Kategori Jasa Profesional, Ilmiah Dan Teknis, Golongan Pokok Kegiatan Kantor Pusat Dan Konsultasi Manajemen, Golongan Konsultasi Manajemen, Sub Golongan Konsultasi Manajemen Area Kerja Pemasaran.</p>
                        <p>
                        Peserta akan mendapatkan pengetahuan tentang riset kata kunci dan optimasi SEO website untuk menjadi manajer pemasaran, cakupan tugas, okupasi manajer pemasaran dan pentingnya riset kata kunci dan optimasi SEO Website, pengenalan SEO dalam Digital Marketing, membangun website, menulis konten, teknik SEO on page dan offpage, dan audit SEO.
 
                       </p>
                    </li>
                    <li>
                      <b>Menyusun Surat Perjanjian Bahasa Inggris Bagi Calon Staf Legal</b>
                      <v-card class="mx-auto py-2" elevation="0">
                      <img
                      src="@/assets/images/21.png"
                        class="img-kategori"
                      />
                      </v-card>
                      <p>
                        Menyusun Surat Perjanjian Bahasa Inggris bagi Calon Staf Legal adalah kelas yang disusun untuk peserta yang ingin belajar Bahasa Inggris khususnya bagi yang berprofesi sebagai Staf Legal. Pelatihan ini menggunakan metode ajar webinar. Kelas ini merujuk pada SKKNI Nomor 183 Tahun 2016 tentang Tenaga Administrasi Profesional dan SKKNI Nomor 27 Tahun 2009 tentang Bahasa Inggris Tenaga Administrasi Profesional.</p>
                        <p> 
                        Peserta akan mendapatkan pengetahuan tentang menggunakan Bahasa Inggris bagi calon staf legal meliputi penggunaan kosa kata Bahasa Inggris yang terdapat pada surat perjanjian kerjasama dalam Bahasa Inggris, penggunaan istilah yang terdapat dalam surat perjanjian kerjasama berbahasa Inggris, penggunaan grammar (Simple present, Simple Future, dan Passive Voices) bahasa Inggris sederhana yang terdapat pada surat perjanjian kerjasama berbahasa Inggris, penggunaan kosakata dan grammar bahasa Inggris sederhana untuk menyusun surat perjanjian kerja sama dalam bahasa Inggris, dan penggunaan sikap komunikatif (pemberian saran/masukan/pendapat) mengenai isi surat perjanjian kerjasama dengan menggunakan bahasa Inggris.
 
                       </p>
                    </li>
                    <li>
                      <b>Mengolah Data Penjualan Dengan Microsoft Excel Untuk Menjadi Admin Input Data</b>
                      <v-card class="mx-auto py-2" elevation="0">
                      <img
                      src="@/assets/images/11.png"
                        class="img-kategori"
                      />
                      </v-card>
                      <p>
                        Mengolah Data Penjualan dengan Microsoft Excel untuk Menjadi Admin Input Data adalah kelas yang disusun khusus untuk peserta yang ingin belajar Data Entry khususnya bagi yang berprofesi sebagai Admin Input Data. Pelatihan ini menggunakan metode ajar webinar. Kelas ini merujuk pada SKKNI Nomor 56 Tahun 2018.</p>
                        <p>
                        Peserta akan mendapatkan pengetahuan tentang menggunakan Microsoft Excel untuk Menjadi Admin Input Data meliputi Pengenalan Microsoft Excel dan Formula Dasar Microsoft Excel: Formula Dasar, Formula Dasar Microsoft Excel: Formula Lanjutan, Mengatur dan Merapikan Data Penjualan, Mengolah Data Penjualan Menggunakan Seri Formula IF, Mengolah Data Penjualan Menggunakan Formula Look Up dan Pivot Table.
                       </p>
                    </li>
                    <li>
                      <b>Menyusun Laporan Keuangan Dengan Microsoft Excel Untuk Menjadi Staf Akuntan</b>
                      <v-card class="mx-auto py-2" elevation="0">
                      <img
                      src="@/assets/images/9.png"
                        class="img-kategori"
                      />
                      </v-card>
                      <p>
                        Menyusun Laporan Keuangan dengan Microsoft Excel untuk Menjadi Staf Akuntan adalah kelas yang disusun untuk peserta yang ingin belajar menyusun laporan keuangan menggunakan microsoft excel khususnya bagi yang berprofesi sebagai Staf Akuntan. Pelatihan ini menggunakan metode ajar webinar. Kelas ini merujuk pada SKKNI Nomor 182 Tahun 2013.</p>
                        <p>
                      Peserta akan mendapatkan pengetahuan tentang menggunakan microsoft excel untuk menjadi staf akuntan meliputi pengenalan okupasi beserta cakupan tugas staf akuntansi dan pengenalan siklus akuntansi, logika akuntansi dan siklus akuntansi (jurnal umum dan jurnal khusus), siklus akuntansi (berupa neraca saldo, neraca lajur, ayat jurnal penyesuaian dan neraca saldo setelah penyesuaian, membuat sistem akuntansi menggunakan microsoft excel dan menyusun laporan keuangan (memproses data dan menyajikan laporan keuangan).
                       </p>
                    </li>
                    <li>
                      <b>Mengelola Gaji Karyawan Dengan Microsoft Excel Untuk Menjadi Staf Personalia</b>
                      <v-card class="mx-auto py-2" elevation="0">
                      <img
                      src="@/assets/images/13.png"
                        class="img-kategori"
                      />
                      </v-card>
                      <p>
                        Mengelola Gaji Karyawan dengan Microsoft Excel Untuk Menjadi Staf Personalia adalah kelas yang disusun untuk  peserta yang ingin belajar tentang mengelola gaji karyawan khususnya bagi yang berprofesi sebagai Staff Personalia. Pelatihan ini menggunakan metode ajar webinar. Kelas ini merujuk pada SKKNI Nomor 149 Tahun 2020 Tentang Manajemen Sumber Daya Manusia</p>
                        <p>
                        Peserta akan mendapatkan pengetahuan tentang Pengenalan Dasar-Dasar Kompensasi dan Benefit, Struktur Jabatan dan Skala Upah Karyawan, Menghitung Komponen Penambah Gaji, Menghitung Komponen Pengurang Gaji, Laporan Gaji Karyawan. 
                       </p>
                    </li>
                    <li>
                      <b>Menyusun Surat Bisnis Dalam Bahasa Inggris Bagi Calon Sekretaris</b>
                      <v-card class="mx-auto py-2" elevation="0">
                      <img
                      src="@/assets/images/5.png"
                        class="img-kategori"
                      />
                      </v-card>
                      <p>
                        Menyusun Surat Bisnis dalam Bahasa Inggris Bagi Calon Sekretaris adalah kelas yang disusun untuk  peserta yang ingin belajar tentang menyusun surat bisnis khususnya bagi yang berprofesi sebagai sekretaris. Pelatihan ini menggunakan metode ajar webinar. Kelas ini merujuk pada SKKNI Nomor 27 Tahun 2009 Bahasa Inggris untuk Tenaga Administrasi Profesional</p>
                        <p>
                        Peserta akan mendapatkan pengetahuan tentang Surat Bisnis dalam Bahasa Inggris dan Penyusunannya, Penyusunan Surat Bisnis “letter of inquiry” dan  “letter of order” dalam Bahasa Inggris, Penyusunan surat bisnis “letter of request” dan “letter of reservation” dalam Bahasa Inggris, Penyusunan surat bisnis “letter of confirmation” dan “letter of reminder” dalam Bahasa Inggris, Penyusunan surat bisnis “adjustment/apology” dalam Bahasa Inggris.
 
                       </p>
                    </li>
                    <li>
                      <b>Mengembangkan Konten Di Media Sosial Untuk Menjadi Spesialis Pemasaran Digital</b>
                      <v-card class="mx-auto py-2" elevation="0">
                      <img
                      src="@/assets/images/7.png"
                        class="img-kategori"
                      />
                      </v-card>
                      <p>
                        Mengembangkan Konten di Media Sosial Untuk Menjadi Spesialis Pemasaran Digital adalah kelas yang disusun khusus untuk peserta yang ingin belajar mengembangkan konten di media sosial khususnya bagi yang berprofesi sebagai spesialis pemasaran digital. Pelatihan ini menggunakan metode ajar webinar. Kelas ini merujuk pada SKKNI Nomor 124 Tahun 2022 Tentang Manajemen Bidang Pemasaran.</p>
                        <p>
                        Peserta akan mendapatkan materi tentang Pengenalan Social Media Marketing, Merencanakan Ide Konten, Memproduksi Konten, Memahami Optimasi & Promosi Konten di Berbagai Platform Sosial Media (YouTube,TikTok, Instagram), dan Menganalisis & Membuat Laporan Performa Social Media. 
                       </p>
                    </li>
                     <li>
                      <b>Merencanakan Strategi Pemasangan Iklan Di Media Sosial Untuk Menjadi Manajer Periklanan</b>
                      <v-card class="mx-auto py-2" elevation="0">
                      <img
                      src="@/assets/images/17.png"
                        class="img-kategori"
                      />
                      </v-card>
                      <p>
                        Merencanakan Strategi Pemasangan Iklan di Media Sosial Untuk Menjadi Manajer Periklanan adalah kelas yang disusun khusus untuk peserta yang ingin belajar Profesional Pemasaran khususnya bagi yang berprofesi sebagai Manajer Periklanan. Pelatihan ini menggunakan metode ajar webinar. Kelas ini merujuk pada SKKNI Nomor 389 Tahun 2013.</p>
                        <p>
                        Peserta akan mendapatkan pengetahuan tentang Pengenalan profesi dan iklan di media sosial, Memilih audience dan penempatan iklan, Mendesain kreatif iklan, Strategi kampanye dan laporan iklan, Materi tingkat lanjut Facebook Pixel

                       </p>
                    </li>
                    <li>
                      <b>Mengelola Kinerja Karyawan Untuk Menjadi Manajer Sumber Daya Manusia</b>
                      <v-card class="mx-auto py-2" elevation="0">
                      <img
                      src="@/assets/images/21.png"
                        class="img-kategori"
                      />
                      </v-card>
                      <p>
                        Mengelola Kinerja Karyawan Untuk Menjadi Manajer Sumber Daya Manusia adalah kelas yang disusun untuk peserta yang ingin belajar mengelola kinerja karyawan khususnya bagi yang berprofesi sebagai manajer sumber daya manusia. Pelatihan ini menggunakan metode ajar webinar. Kelas ini merujuk pada SKKNI Nomor 149 Tahun 2020 Tentang Manajemen Sumber Daya Manusia.</p>
                        <p>
                        Peserta akan mendapatkan pengetahuan tentang Memahami dasar-dasar manajemen kinerja karyawan, merancang indikator kinerja karyawan, merancang proses monitoring kinerja karyawan, merancang proses evaluasi kinerja karyawan, menyusun perencanaan peningkatan kinerja karyawan
                       </p>
                    </li>
                    <li>
                      <b>Merumuskan Kebijakan Organisasi Dan Strategi Pengelolaan SDM Untuk Menjadi Manajer Sumber Daya Manusia </b>
                      <v-card class="mx-auto py-2" elevation="0">
                      <img
                      src="@/assets/images/Merumuskan Kebijakan Organisasi dan Strategi Pengelolaan SDM Untuk Menjadi Manajer Sumber Daya Manusia.png"
                        class="img-kategori"
                      />
                      </v-card>
                      <p>
                        Merumuskan Kebijakan Organisasi dan Strategi Pengelolaan SDM Untuk Menjadi Manajer Sumber Daya Manusia adalah kelas yang disusun untuk peserta yang ingin belajar merumuskan kebijakan organisasi dan strategi pengelolaan SDM bagi yang ingin berprofesi sebagai Manajer Sumber Daya Manusia. Pelatihan ini menggunakan metode ajar webinar. Kelas ini merujuk pada SKKNI Nomor 149 Tahun 2020 Tentang Manajemen Sumber Daya Manusia. </p> 
                        <p>
                        Peserta akan mendapatkan pengetahuan tentang dasar-dasar manajemen dan perumusan kebijakan SDM di perusahaan, merancang kebijakan SDM bagi karyawan di perusahaan, merancang operasional pengelolaan SDM di perusahaan, merancang desain hubungan industrial yang baik di perusahaan, dan sikap teliti dan cermat dalam merumuskan kebijakan perusahaan.
                       </p>
                    </li>
                    </ol>
                  
                </div>
                <div id="faq" class="mb-4">
                  <h3 class="font-weight-bold pb-2">FAQ</h3>
                  <v-expansion-panels accordion outlined>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Apakah ada batas waktu penggunaan saldo pelatihan?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Ya, kamu hanya dapat membeli pelatihan pertamamu dalam waktu 15 (lima belas) hari kalender setelah kamu mendapatkan pemberitahuan penetapan sebagai Penerima Kartu Prakerja dari Manajemen Pelaksana. Jika melebihi batas waktu tersebut dan kamu belum membeli pelatihan, Kartu Prakerja kamu akan dinonaktifkan/dicabut kepesertaannya dan kamu tidak dapat mengikuti kembali Program Kartu Prakerja. Saldo bantuan pelatihanmu juga akan hangus dan akan dikembalikan ke Rekening Negara.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Apakah saya dapat membeli lebih dari satu pelatihan?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Bisa, namun kamu hanya boleh membeli pelatihan yang kedua setelah kamu menyelesaikan pelatihan pertama kamu dan mengisi rating ulasan. Pastikan kamu masih mempunyai sisa saldo yang cukup dan membeli pelatihan kedua dalam kurun waktu 15 (lima belas) hari kalender setelah penyelesaian pelatihan pertama dan mengisi rating ulasan.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Bagaimana cara membeli pelatihan dari YEC.CO.ID?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Kamu bisa melakukan pembelian pelatihan dari YEC.CO.ID melalui platform digital berikut:
                        <ul class="mb-3">
                          <li>Tokopedia: https://www.tokopedia.com/kartu-prakerja/partner/yec-co-id</li>
                          <li>Karier.mu: https://prakerja.karier.mu/mitra/yec-co-id</li>
                          <li>Bukalapak:https://www.bukalapak.com/kartu-prakerja/lembaga/pt-yureka-edukasi-cipta-yeccoid-3804?source=tab-merchant</li>
                          <li>Pijar Mahir: https://pijarmahir.id/institution-partner/yec</li>
                          <li>Pintar: https://pintar.co/kartuprakerja#/ </li>
                        </ul>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Bagaimana jika saldo pelatihan saya tidak cukup untuk membeli pelatihan yang saya mau?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Saat ini, kamu hanya dapat membeli pelatihan dengan besaran sama atau kurang dari jumlah pagu kamu. Jadi, pastikan saldo kamu cukup ya untuk membeli pelatihan yang kamu mau.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Bagaimana jika saya gagal menggunakan saldo pelatihan di Platform Digital?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Pastikan kamu telah memasukkan nomor Kartu Prakerja dan OTP yang benar pada saat membeli pelatihan di Platform Digital. Selain itu, pastikan juga kamu telah memenuhi kriteria untuk mengikuti pelatihan, seperti batas minimal usai dan batas minimal pendidikan. Jika masih terdapat kendala, kamu dapat menghubungi customer service Platform Digital:
                        <ol class="mb-2">
                          <li>Tokopedia: https://www.tokopedia.com/help </li>
                          <li>Karier.mu: +62 813 1579 2171 (WhatsApp)</li>
                          <li>Bukalapak: 021-508 13333</li>
                          <li>Pijar Mahir: +62 821 1111 3630 (WhatsApp)</li>
                          <li>Pintar: www.pintar.co</li>  
                        </ol>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Bagaimana jika pembelian pelatihan saya bermasalah?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Saat membeli pelatihan, pastikan bahwa saldo pelatihan kamu masih tersedia, koneksi internet stabil dan kamu telah memasukkan nomor Kartu Prakerja dan OTP yang benar. Kamu juga harus memastikan kamu memenuhi kriteria untuk mengikuti pelatihan, seperti batas minimal usia dan batas minimal pendidikan. Jika masih bermasalah, kamu bisa menghubungi customer service Mitra Platform Digital di bawah ini:
                        <ol class="mb-2">
                          <li>Tokopedia: https://www.tokopedia.com/help </li>
                          <li>Karier.mu: +62 813 1579 2171 (WhatsApp)</li>
                          <li>Bukalapak: 021-508 13333</li>
                          <li>Pijar Mahir: +62 821 1111 3630 (WhatsApp)</li>
                          <li>Pintar: www.pintar.co</li>  
                        </ol>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Apakah pelatihan yang sudah saya beli namun belum diikuti bisa diganti?</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Kamu tidak bisa mengganti pelatihan yang sudah dibeli meskipun belum kamu ikuti. Kamu harus mengikuti pelatihan tersebut sampai selesai.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Bagaimana saya bisa redeem pelatihan? </v-expansion-panel-header>
                    <v-expansion-panel-content>
                    <p>Untuk bisa redeem pelatihan, kamu harus melihat kode redeem yang ada bisa dilihat di email atau detail pelatihan di dashboard kamu. Buka kotak masuk email yang kamu daftarkan ke akun Prakerja. Cari email dengan kata kunci “Pembelian Pelatihan” seperti di bawah ini. Kamu akan menemukan kode redeem yang bisa kamu gunakan untuk redeem pelatihan.</p>
                      <img
                      src="@/assets/images/img-mobile-prakerja-1.png"
                        class="img-list-mob"
                      />
                      <p>Selain di email, kamu juga bisa melihat kode redeem di detail pelatihan yang sudah kamu beli di dashboard. Klik pelatihan yang sudah kamu beli di Pelatihan Saya.</p>
                      <img
                      src="@/assets/images/img-mobile-prakerja-2.png"
                        class="img-list-mob"
                      />
                      <p>Jika kamu membeli pelatihan yang diselenggarakan secara online, kamu bisa melihat kode redeem dengan tampilan di bawah ini.</p>
                      <img
                      src="@/assets/images/img-mobile-prakerja-3.png"
                        class="img-list-mob"
                      />
                      <p>Jika kamu melakukan pembelian pelatihan dari lembaga YEC.CO.ID, maka silakan akses bakat.yec.co.id untuk meredeem kode redeem tersebut. Kode redeem dan Kode voucher di bakat.yec.co.id hanya dapat diredeem minimal 1 jam sebelum jadwal yang kamu pilih dimulai, dan maksimal 1 jam setelah jadwal yang kamu pilih dimulai. Berikut tata caranya :</p>
                      <ol>
                        <li>Masukkan kode redeem yang ada di menu “Kelas Saya”  untuk mengikuti pelatihan</li>
                        <img
                        src="@/assets/images/img-mobile-yec-1.png"
                          class="img-list-mob"
                        />
                        <li>Kemudian masukkan kode voucher yang kamu dapatkan dari transaksi/bukti pembayaran/invoice platform digital (Tokopedia/Karier.mu/Bukalapak/Pijar Mahir/Pintar)</li>
                        <img
                        src="@/assets/images/img-mobile-yec-2.png"
                          class="img-list-mob"
                        />
                        <li>Kemudian klik “redeem” maka nanti kelas yang kamu beli akan muncul di menu “Kelas Saya”</li>
                        <li>Silakan klik kelas yang kamu beli, kemudian klik “aktivitas”</li>
                        <li>Ikuti semua aktivitas yang tersedia hingga progressnya 100 persen hingga mendapatkan sertifikat</li>
                      </ol>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Bagaimana jika redeem pelatihan saya bermasalah? </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Ada beberapa penyebab kode redeem kamu bermasalah, yaitu:
                        <ol class="mb-2">
                          <li>Kode redeem tidak ditemukan</li>
                          <li>Tipe pelatihan tidak sesuai</li>
                          <li>Kode redeem bukan milik lembaga pelatihan yang kamu pilih</li>
                          <li>Kode redeem sudah dibatalkan</li>
                          <li>Transaksi sudah dibatalkan</li>
                          <li>Kode redeem sudah digunakan</li>
                          Pastikan kamu sudah mengisi kode redeem sesuai yang tertera di email dan detail pelatihan di dashboard kamu. Jika masih ada kendala, silakan hubungi Call Center Prakerja di 08001503001 atau isi link form pengaduan di <a href="https://bantuan.prakerja.go.id/hc/id/requests/new">https://bantuan.prakerja.go.id/hc/id/requests/new</a>
                        </ol>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Apakah ada tes yang harus saya ikuti sebelum memulai pelatihan?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Ya, penerima Kartu Prakerja wajib mengikuti pre-test atau tes awal terlebih dahulu di lembaga pelatihan sebelum memulai pelatihan. Tes awal ini terdiri dari beberapa pertanyaan mengenai materi pelatihan yang kamu pilih. Tenang saja, tes awal ini hanya bertujuan untuk melihat pengetahuan awal kamu, kok!</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Apakah selama pelatihan daring wajib on cam?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Wajib, silakan on cam selama pelatihan berlangsung agar bukti kehadiran kamu dapat terecord.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Bagaimana format penamaan zoom bagi peserta yang hadir dalam sesi pelatihan daring (saat live webinar) tersebut?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Silakan kamu mengubah nama akun zoom sesuai format di bawah ini, agar tim dapat melakukan pengecekan kehadiran:</p>
                        <ol>
                          <li>Untuk Peserta Kartu Prakerja: “Kartu Prakerja - Nama Lengkap Peserta Sesuai KTP”</li>
                          <li>Untuk Peserta Non Kartu Prakerja: “Nama Lengkap”</li>
                        </ol>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Bagaimana jika saya tidak dapat melanjutkan pelatihan karena ada kendala di video/webinar saya?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Kamu harus memastikan koneksi jaringan stabil saat mengambil pelatihan dan coba menggunakan perangkat lain terlebih dahulu. Jika kamu masih mengalami kendala, kamu bisa menghubungi CS YEC.CO.ID khusus pelatihan Prakerja di +62 853-3584-9962 (WhatsApp chat)</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Saya sudah menyelesaikan pelatihan, kok sertifikat belum muncul di dashboard Prakerja?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Sertifikat pelatihan akan muncul di dashboard kamu setelah menyelesaikan pelatihan paling lambat satu hari kerja sesudah Manajemen Pelaksana menerima laporan penyelesaian pelatihan dari Platform Digital. Jika sertifikat belum muncul di dashboard lebih dari 7 (tujuh) hari kerja.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Mengapa nama di sertifikat tidak sesuai dengan nama saya?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Sertifikat pelatihan diterbitkan langsung oleh Lembaga Pelatihan. Jika ada kekeliruan dalam penulisan nama di sertifikat pelatihan, kamu bisa menghubungi Lembaga Pelatihan untuk konfirmasi lebih lanjut. Selain itu, pastikan kamu menggunakan nama asli kamu di profil akun Platform Digital dan akun bakat.yec.co.id agar nama di sertifikat sama dengan nama aslimu (jika kamu melakukan pembelian pelatihan dari YEC.CO.ID)</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Apa yang harus saya lakukan setelah menyelesaikan pelatihan?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Setelah menyelesaikan pelatihan dan mendapatkan sertifikat, kamu harus segera mengisi rating dan ulasan pelatihan di dashboard kamu. Pastikan kamu memberikan rating dan ulasan dengan jujur sesuai pengalaman pelatihan kamu.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Bagaimana cara mengisi rating dan ulasan di dashboard saya?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ul class="mb-2">
                          <li>
                            Cek Pelatihan Saya di dashboard kamu dan pastikan tombol Tulis Ulasan sudah aktif dan berwarna biru. Kilik tombol tersebut.
                          </li>
                          <li>
                            Akan muncul pop up untuk mulai mengisi ulasan. Klik Mulai Beri Ulasan
                          </li>
                          <li>
                            Pilih bintang untuk mengisi rating sesuai dengan tingkat kepuasanmu saat mengikuti pelatihan. Bintang 1 (satu) artinya kamu sangat tidak puas dan bintang 5 (lima) artinya kamu sangat puas. Jika sudah memberi rating, klik Lanjut
                          </li>
                          <li>
                            Tulis ulasan dengan jujur untuk meningkatkan kualitas pelatihan yang kamu ambil. Jika sudah, klik Lanjut
                          </li>
                          <li>
                            Cek kembali rating dan ulasan yang sudah kamu isi. Pastikan ya sudah sesuai dengan pengalaman kamu selama mengikuti pelatihan! Jika sudah sesuai, klik Kirim
                          </li>
                        </ul>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Jika nilai tes akhir pelatihan saya tidak memuaskan, apakah saya tetap bisa menerima insentif?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Kamu tetap akan menerima insentif selama kamu sudah menyelesaikan pelatihan yang ditandai dengan sertifikat penyelesaian pelatihan serta mengisi rating dan ulasan di dashboard Prakerja kamu. Nilai tes akhir pelatihan kamu hanya digunakan untuk menilai pemahaman kamu terhadap pelatihan yang telah diikuti.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Siapa saja yang bisa mendapatkan insentif?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Insentif hanya diberikan kepada pemegang Kartu Prakerja yang sah yang telah menyelesaikan pelatihan pertama, mengisi rating dan ulasan pelatihan pertama dan telah menyambungkan rekening bank atau e-money.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Apa saja jenis insentif dan berapa jumlahnya?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Insentif terdiri dari 2 (dua) jenis:</p>
                        <ol class="mb-2">
                          <li>
                            Insentif biaya mencari kerja sebanyak 1 (satu) kali sebesar Rp600.000 (enam ratus ribu Rupiah)
                          </li>
                          <li>
                            Insentif pengisian survei evaluasi sebesar Rp50.000 (lima puluh ribu Rupiah) yang diberikan paling banyak 2 (dua) kali.
                          </li>
                        </ol>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Kapan saya mendapatkan insentif?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p class="font-weight-bold">Insentif biaya mencari kerja</p>
                        <p>Jika kamu lolos menjadi Penerima Kartu Prakerja, kamu akan menerima insentif biaya mencari kerja setelah:</p>
                        <ul class="mb-2">
                          <li>
                            Telah menyelesaikan Pelatihan yang ditandai dengan adanya sertifikat
                          </li>
                          <li>
                            Telah memberikan ulasan (review) dan penilaian (rating) terhadap pelatihan di dashboard kamu.
                          </li>
                          <li>
                            Jika Penerima Kartu Prakerja mengikuti lebih dari satu pelatihan, insentif biaya mencari kerja hanya diberikan pada saat penyelesaian pelatihan yang pertama. Tidak ada insentif untuk pelatihan kedua dan seterusnya.
                          </li>
                          <li>
                            Telah berhasil menyambungkan nomor rekening bank atau e-money di akun situs www.prakerja.go.id
                          </li>
                          <li>
                            Nomor rekening bank atau e-money yang didaftarkan telah tervalidasi (menggunakan NIK yang sama dengan NIK terdaftar di Kartu Prakerja dan sudah KYC atau akun e-money sudah premium/upgrade) oleh bank/perusahaan e-money terkait.
                          </li>
                        </ul>
                        <p class="font-weight-bold">Insentif pengisian survei evaluasi</p>
                        <p>Akan diterima jika Penerima Kartu Prakerja telah mengisi survei evaluasi pada dashboard akun kamu di situs www.prakerja.go.id untuk mengetahui evaluasi efektivitas Program Kartu Prakerja.</p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Kapan paling lambat saya harus menyelesaikan pelatihan untuk mendapatkan insentif?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Untuk mendapatkan insentif, kamu harus segera menyelesaikan pelatihan pertama dan memberikan rating dan review pelatihan paling lambat di bulan Desember di tahun yang sama. Kamu juga harus ingat bahwa untuk menerima insentif, kamu harus menyelesaikan pelatihan pertama kamu paling lambat tanggal 4 Desember pada tahun anggaran berjalan. Jadi, pastikan kamu menyelesaikan pelatihan pertama dan berikan rating dan review secepatnya agar insentif kamu tidak hangus, ya!
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Apakah saya bisa mendapatkan insentif jika belum menjadi Penerima Kartu Prakerja?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Tidak, kamu hanya bisa mendapatkan insentif ketika kamu telah menjadi Penerima Kartu Prakerja yang sah, telah menyelesaikan pelatihan pertama kamu dan memenuhi persyaratan sebagaimana disebutkan di atas.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Di mana saya bisa mengecek jumlah insentif yang akan saya terima?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Kamu bisa cek pada akun dashboard kamu. Pastikan kamu cek statusnya secara berkala, ya.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Apa saja yang membuat insentif saya gagal dicairkan?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>Insentif kamu bisa gagal dicairkan apabila:</p>
                        <ul class="mb-2">
                          <li>
                            Belum mengisi ulasan (review) pelatihan di dashboard kamu.
                          </li>
                          <li>
                            Belum memberikan penilaian (rating) pelatihan di dashboard kamu.
                          </li>
                          <li>
                            Nomor rekening bank atau akun e-money yang kamu daftarkan pada Kartu Prakerja tidak aktif atau bermasalah.
                          </li>
                          <li>
                            Akun e-money kamu belum di-upgrade atau melakukan KYC (verifikasi KTP dan swafoto).
                          </li>
                          <li>
                            Batas transaksi dalam 1 (satu) bulan dan saldo akun e-money kamu melebihi batas maksimum yang diperbolehkan oleh penyelenggara e-money atau ketentuan peraturan perundang-undangan.
                          </li>
                          <li>
                            Data diri yang didaftarkan pada e-money tidak sesuai dengan yang didaftarkan pada akun Kartu Prakerja.
                          </li>
                        </ul>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Apakah insentif diberikan secara tunai atau non-tunai?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Insentif kamu akan diberikan non-tunai dengan cara ditransfer ke rekening bank atau akun e-wallet yang telah kamu daftarkan.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Bagaimana insentif saya akan disalurkan?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Insentif akan disalurkan melalui akun e-wallet atau rekening bank yang telah kamu daftarkan di akun kamu melalui situs www.prakerja.go.id
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Berapa lama proses pencairan insentif ke rekening atau e-money saya?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Pencairan insentif ke rekening atau e-wallet kamu membutuhkan waktu 3-5 hari kerja sejak tanggal penjadwalan insentif muncul di dashboard akun kamu. Jika lebih dari 5 (lima) hari kerja kamu masih belum menerima insentif, silakan hubungi kami melalui Call Center Prakerja di 08001503001
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Mengapa pencairan insentif saya belum terjadwal?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Jika belum ada jadwal pencairan insentif di dashboard kamu, pastikan kembali bahwa kamu sudah menyelesaikan pelatihan serta memberikan rating dan ulasan pelatihan. Silakan menunggu 1x24 jam untuk sinkronisasi jadwal pencairan insentif kamu.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Bagaimana jika saya belum menerima insentif padahal sudah ada jadwal pencairan insentif di dashboard saya?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Pencairan insentif dilakukan secara bertahap. Oleh karena itu, pastikan kamu sudah memenuhi seluruh syarat dan ketentuan yang berlaku untuk mendapatkan insentif. Lakukan juga pengecekan berkala di dashboard akun kamu dan riwayat rekening bank/e-wallet yang telah terhubung dengan akun Prakerja. Jika dalam waktu 5 hari kerja kamu masih belum menerima insentif, silakan hubungi Call Center Prakerja di 08001503001.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Apakah saya mendapatkan insentif lain setelah mendapatkan insentif biaya mencari kerja?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Setelah mendapat insentif biaya mencari kerja, kamu juga dapat memperoleh insentif pengisian survei evaluasi dengan mengisi survei evaluasi sesuai jadwal yang tersedia di dashboard kamu. Insentif ini hanya diberikan kepada penerima Kartu Prakerja yang mengisi survei evaluasi paling lambat tanggal 10 Desember tahun anggaran berjalan. Ingat ya, isi survei evaluasi kamu dengan jujur sesuai dengan keadaan kamu!.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Mengapa saya tidak mendapat jadwal pengisian survei evaluasi?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p>
                          Jadwal pengisian survei evaluasi hanya diberikan kepada Penerima Kartu Prakerja yang telah menerima insentif biaya mencari kerja. Pastikan kamu sudah menerima insentif tersebut dan cek terus dashboard kamu untuk mengetahui jadwal terbaru.
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Kontak Admin YEC Prakerja </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ul class="mb-2">
                          <li>
                            Group FB Panduan Kartu Prakerja <br>
                            <a href="https://www.facebook.com/groups/prakerja.yec">
                              <div>https://www.facebook.com/groups/prakerja.yec</div>
                            </a>
                          </li>
                          <li>
                            Group Telegram Panduan Prakerja by YEC <br>
                            <a href="https://t.me/joinchat/A9ea-qrWsoozZDE1">https://t.me/joinchat/A9ea-qrWsoozZDE1</a>
                          </li>
                          <li>Email : prakerja@yec.co.id</li>
                          <li>Instagram : @bakat.yec</li>
                          <li>
                            CS WhatsApp : +62 853-3584-9962 (Hanya Chat)
                          </li>
                        </ul>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
  </div>
</template>

<script>
// import resources from "@/store/resources"

export default {
  name: "public-prakerja",
  components: {   },
  data: () => ({
    // model:null,
    // resources: [],
  }),
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>
#tab-ver .v-list-item-group .v-list-item--active {
    color: #f05326;
    border-left: 2px solid;
}
.sticky-sidebar{
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
}
</style>